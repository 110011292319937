import React, { Component } from 'react';
import './Note.css';

export default class Note extends Component {
    constructor(props) { 
        super(props);
        this.state = {
            isAltered: props.isAltered,
            stringId: props.stringId,
            emptyString: props.emptyString,
            baseClassName: (props.emptyString ? 'EmptyString' : 'Note' + (!props.isAltered ? ' NoteNm':'')),
            className: (props.emptyString ? 'EmptyString' : 'Note' + (!props.isAltered ? ' NoteNm':'')),
            toSel: props.toSel,
            owner: props.owner,
            noteRef: props.noteRef,
            noteName: props.noteName,
            noteId: props.noteId,
            objClass: props.objClass
        }; 
        this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
        this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
    }

    mouseEnterHandler(){
        var fretboard = this.state.owner.state.owner;
        fretboard.setState({'toSel':this.state.noteRef});
        return true;
    }

    mouseLeaveHandler(){
        var fretboard = this.state.owner.state.owner;
        fretboard.setState({'toSel':0});
        return true;
    }


    componentDidUpdate(propsOld) {
        if (this.props.noteRef !== propsOld.noteRef) {
            this.setState({noteRef: this.props.noteRef});
        }
        if (this.props.noteName !== propsOld.noteName) {
          this.setState({noteName: this.props.noteName});
        }
        if (this.props.noteId !== propsOld.noteId) {
            this.setState({noteId: this.props.noteId});
        }
        if (this.props.objClass !== propsOld.objClass) {
            this.setState({objClass: this.props.objClass});
        }
        if (this.props.toSel !== propsOld.toSel) {
            this.setState({toSel: this.props.toSel});

            if(this.props.toSel==null || this.props.toSel===0){
                this.setState({className: this.state.baseClassName});
            } else if(this.props.toSel===this.state.noteRef){
                this.setState({className: this.state.baseClassName+' NoteSel'});
            } 
        }
      }
                
    render(props) {
        //,{this.status.objClass}
        return(
            <div className={this.state.className} stringId={this.state.stringId} noteRef={this.state.noteRef} noteid={this.state.noteId} notename={this.state.noteName} 
                 onMouseLeave={this.mouseLeaveHandler}
                 onMouseEnter={this.mouseEnterHandler}
                 >
                {this.state.noteName}
            </div>
        )
    }
}