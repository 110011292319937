import React, { Component } from 'react';
import Note from './Note';
import './String.css';

export default class String extends Component {
  
    constructor(props) { 

        super(props);
        this.state = { 
            toSel: props.toSel,
            owner: props.owner,
            codify: props.codify,
            id: props.id,
            notes: {
                'IT': { 1: 'DO', 2: 'RE', 3:'MI', 4: 'FA', 5: 'SOL', 6:'LA', 7:'SI' },
                'EN': { 1: 'C',  2: 'D',  3:'E',  4: 'F',  5: 'G',   6:'A',  7:'B' }
            },
            haveNoSemiTone: [3,7]
        }; 
        this.renderNotes = this.renderNotes.bind(this);
    } 

    componentDidUpdate(propsOld) {
        if (this.props.id !== propsOld.id) {
            this.setState({id: this.props.id});
        }
        if (this.props.codify !== propsOld.codify) {
            this.setState({codify: this.props.codify});
        }
        if (this.props.toSel !== propsOld.toSel) {
            this.setState({toSel: this.props.toSel});
        }
      }

    renderNotes(){
        //console.log('*******',this.state.id);

        let nToPrint = [],
            iCnt = 0;

        for (let note = this.state.id; note <= 7; note++) {
            iCnt++;
            let noteN=note+'n',
                noteS=note+'s',
                noteRef=note,
                noteRefS=note+'#',
                noteName=this.state.notes[this.state.codify][note],
                noteNameS=this.state.notes[this.state.codify][note]+"#";

                if(note > this.state.id){
                    nToPrint.push(<Note key={noteN} stringId={this.state.id} isAltered={false} noteName={noteName} noteRef={noteRef} noteId={note} toSel={this.state.toSel} owner={this} />);
                }
                if(this.state.haveNoSemiTone.indexOf(note)===-1){ 
                    nToPrint.push( <Note key={noteS} stringId={this.state.id} isAltered={true} noteName={noteNameS} noteRef={noteRefS} noteId={note} toSel={this.state.toSel} owner={this} />);
                }
        }
        for (let note = 1; note <= this.state.id; note++) {
            iCnt++;
            let noteN=note+'nS',
                noteS=note+'sS',
                noteRef=note,
                noteRefS=note+'#',
                noteName=this.state.notes[this.state.codify][note],
                noteNameS=this.state.notes[this.state.codify][note]+"#";

                nToPrint.push(<Note key={noteN} stringId={this.state.id} isAltered={false} noteName={noteName} noteRef={noteRef} noteId={note} toSel={this.state.toSel} owner={this} />);

                if(this.state.haveNoSemiTone.indexOf(note)===-1 && iCnt<8){ 
                    nToPrint.push( <Note key={noteS} stringId={this.state.id} isAltered={true} noteName={noteNameS} noteRef={noteRefS} noteId={note} toSel={this.state.toSel} owner={this} />);
                }
        }
        return nToPrint;
    }

    
    render(){

        let note = this.state.id,
            noteN = note,
            noteRef = note,
            noteName=this.state.notes[this.state.codify][note]
        //<div className="EmptyString">{this.state.notes[this.state.codify][this.state.id]}</div>

        return(
            <div className="String">
                <Note key={noteN} stringId={this.state.id} isAltered={false} noteName={noteName} noteRef={noteRef} noteId={note} toSel={this.state.toSel} owner={this} emptyString={true} />
                <div className="Nut">&nbsp;</div>
                {this.renderNotes()}
            </div>
        );
    }  
}