import React, { Component } from 'react';
import './FretSigns.css';

export default class FretSigns extends Component {
  
    constructor(props) { 

        super(props);
        this.state = { 
            numNotes: props.numNotes
        }; 
        this.renderFrets = this.renderFrets.bind(this);
    } 

    componentDidUpdate(propsOld) {
        if (this.props.numNotes !== propsOld.numNotes) {
            this.setState({numNotes: this.props.numNotes});
        }
      }

    renderFrets(){
        let nToPrint = [];
        let cname = 'Fret';

        for (let fret = 1; fret <= this.state.numNotes; fret++) {
            let cnameN = cname + ([3,5,7,9,12].indexOf(fret)!==-1 ? ' FretSel' : '');
            nToPrint.push(<div className={cnameN}>{fret}</div>);
        }
        return nToPrint;
    }

    
    render(){
        return(
            <div className="FretSigns">
                <div className="Fret EmptyStringFret">0</div>
                <div className="NutFret">&nbsp;</div>
                {this.renderFrets()}
            </div>
        );
    }  
}