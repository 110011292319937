import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Fretboard from './components/Fretboard';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';


import PrintIcon from '@mui/icons-material/Print';



import './App.css';

function App() {
  const [codify, setCodify] = useState('IT');
  const [stringsNum, setStringsNum] = useState(4);

  const handleChangeCodify = (e, x) => {
    console.log('handleChangeCodify',x.props.value)
    setCodify(x.props.value);
  };
  const handleChangeStringsNum = (e, x) => {
    console.log('handleChangeStringsNum',x.props.value)
    setStringsNum(x.props.value);
  };

  const handlePrintFretboard = (e, x) => {
      var opt = {
        margin:       1,
        filename:     'fretboard.pdf',
        image:        { type: 'jpeg', quality: 1 },
        html2canvas:  { scale: 2 },
        jsPDF:        { unit: 'mm', format: 'a4', orientation: 'landscape' }
      };
      let source = document.getElementsByClassName('Fretboard')[0],
          titleSource = document.getElementsByClassName('title')[0];;
      source.classList.add('print');
      titleSource.classList.remove('hidden');
      window.html2pdf(source,opt).then(function(){
          source.classList.remove('print');
          titleSource.classList.add('hidden');
      });
  };


  return (
        <div className="App">
          <AppBar position="relative">
            <Toolbar>
              <AutoModeIcon sx={{ mr: 2 }} />
              <Typography variant="h6" color="inherit" noWrap>
                Bass Fretboard Notes
              </Typography>
            </Toolbar>
          </AppBar>
          <br /><br />
          <Toolbar>
          <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="stringsLbl">Strings</InputLabel>
              <Select
                labelId="stringsLbl" id="stringsCombo" value={stringsNum} label="Strings" onChange={handleChangeStringsNum} >
                <MenuItem value={4} selected>4 Strings</MenuItem>
                <MenuItem value={5}>5 Strings</MenuItem>
                <MenuItem value={6}>6 Strings</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="notesLbl" className="marginLeft">Notation</InputLabel>
              <Select
                labelId="notesLbl" id="notesCombo" value={codify} label="Notation" onChange={handleChangeCodify} >
                <MenuItem value='IT' selected>IT</MenuItem>
                <MenuItem value='EN'>EN</MenuItem>
              </Select>
            </FormControl> 


            <FormControl variant="filled" sx={{ m: 1, minWidth: 30 }}>
              <IconButton aria-label="print" color="primary" onClick={handlePrintFretboard}>
                <PrintIcon />
              </IconButton>
            </FormControl> 
          </Toolbar>

          <Fretboard codify={codify} stringsNum={stringsNum} />
          
        </div>
  );
}

export default App;
