import React, { Component } from 'react';
import String from './String';
import FretSigns from './FretSigns';
import './Fretboard.css';

export default class Fretboard extends Component {
    constructor(props) { 
        super(props);
        this.state = { 
            toSel: 0,
            stringsNum: props.stringsNum,
            codify: props.codify,
            bassTypes: {
                4: [3,6,2,5],  //EADG
                5: [7,3,6,2,5], //BEADG
                6: [7,3,6,2,5,1], //BEADGC
            }
        }; 
        // 'EN': { 1: 'C', 2: 'D', 3: 'E', 4: 'F', 5: 'G', 6: 'A', 7: 'B' }

        this.handleChangeStringsNum = this.handleChangeStringsNum.bind(this);
    } 

    handleChangeStringsNum(event,stringsNum) {
        console.log(stringsNum,'stringsHandle');
        this.setState({ 
            stringsNum: stringsNum
          })
    }

    componentDidUpdate(propsOld) {
        if (this.props.stringsNum !== propsOld.stringsNum) {
          this.setState({stringsNum: this.props.stringsNum});
        }
        if (this.props.codify !== propsOld.codify) {
            this.setState({codify: this.props.codify});
        }
        if (this.props.toSel !== propsOld.toSel) {
            this.setState({toSel: this.props.toSel});
        }
    }
 
    render(){
        let bT = JSON.parse(JSON.stringify(this.state.bassTypes[this.state.stringsNum]));
        console.log(this.state.stringsNum,bT,'strings');
        return (
            <div className="Fretboard">
            <div className="title hidden">Bass-Fretboard-Notes.com</div>
            

            {bT.reverse().map((string,index) => {
                    let indexKey = index + 'SN';
                    return ( 
                            <String key={indexKey} id={string} codify={this.state.codify} toSel={this.state.toSel} owner={this} /> 
                           );
                })}

            <FretSigns numNotes={12} owner={this} />
            </div>
            
        );
    }
}